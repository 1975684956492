import agent from '../../api/agent'

const item = {
  namespaced: true,
  state: {
    itemList: [],
    itemCodes: [],
    itemNames: [],
    itemMaster: [],
    item: undefined,
    selectedItem: undefined,
  },
  mutations: {
    updateItemList(state, newValue) {
      state.itemList = newValue
    },
    updateItemcodes(state, newValue) {
      state.itemMaster = []
      state.itemCodes = newValue.map(v => {
        state.itemMaster[v.item_code] = v.item_name

        return v.item_code
      })
      state.itemNames = newValue.map(v => {
        state.itemMaster[v.item_name] = v.item_code

        return v.item_name
      })
    },

    updateSelectedItem(state, newValue) {
      state.selectedItem = newValue
    },
  },
  actions: {
    async loadItemList(context, query) {
      const result = await agent.Items.all(query)
      context.commit('updateItemList', result.data)
    },
    async createItem(context, items) {
      await agent.Items.add(items)
    },
    async loadItem(context, id) {
      const result = await agent.Items.detail(id)
      context.commit('updateSelectedItem', result.data)
    },
    async loadItemCodes(context) {
      const result = await agent.Items.allCodes()
      context.commit('updateItemcodes', result.data)
    },
    async editItem(context, items) {
      const obj = {
        ...items,
      }
      await agent.Items.update(obj)
    },

    async deleteItem(context, id) {
      await agent.Items.delete(id)
    },
  },
}

export default item
