<template>
  <v-app-bar app color="rgba(0,0,0,0)" flat height="0">
    <div id="toolbar">
      <div style="margin-top: 17px; margin-left: 37px">
        <v-img
          v-if="size === 1"
          id="img"
          :src="require('@/views/images/home/n_3262.png')"
          style="position: absolute; top: 30px; left: 40px; width: 154px; height: 37px"
          class="pointer"
          @click="clickLogo"
        ></v-img>
        <v-img
          v-if="size === 2"
          id="img"
          :src="require('@/views/images/home/n_3262.png')"
          style="position: absolute; top: 25px; left: 40px; width: 194px; height: 47px"
          class="pointer"
          @click="clickLogo"
        ></v-img>
        <div v-if="size === 1" class="button1" style="font-size: 14px">
          <span v-for="(item, index) in navItems" :key="index" :class="item.class" @click="navAction(item)">
            {{ item.text }}</span
          >
        </div>
        <div v-if="size === 2" class="button2" style="font-size: 18px">
          <span v-for="(item, index) in navItems" :key="index" :class="item.class" @click="navAction(item)">
            {{ item.text }}</span
          >
        </div>
        <div v-if="size === 1">
          <div v-if="isLoggedIn === false" id="icon1">
            <v-badge color="#41AB55" overlap :content="count" :value="count > 0">
              <img src="@/views/images/home/グループ 3266@2x.png" height="33px" @click="login" />
            </v-badge>
          </div>
          <div v-if="isLoggedIn === true" id="icon2">
            <v-badge color="#41AB55" overlap :content="count" :value="count > 0">
              <img src="@/views/images/home/グループ 3266@2x.png" height="33px" @click="cart" />
            </v-badge>
          </div>
          <v-menu offset-y min-width="150px" absolute>
            <template #activator="{ on, attrs }">
              <div>
                <svg v-if="isLoggedIn === false" id="square1" class="pointer" @click="login">
                  <rect style="fill: rgba(255, 64, 0, 0.7)" x="0" y="35" rx="10" ry="10" width="82" height="33"></rect>
                </svg>
                <div v-if="isLoggedIn === false" id="login1">
                  <span @click="login">ログイン</span>
                </div>
                <div v-if="isLoggedIn === true" id="logout1">
                  <v-icon color="#ff6e40" x-large v-bind="attrs" v-on="on"> mdi-account-outline </v-icon>
                </div>
                <div v-if="isLoggedIn === true" id="name1">
                  <span>{{ showUsername }}</span>
                </div>
              </div>
            </template>
            <v-list style="text-align: right">
              <v-list-item v-for="(item, index) in items" :key="index" @click="selectedPage(item)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="isLoggedIn === false" style="margin-top: 57px; margin-left: 97px">
            <svg id="circle1" class="pointer" @click="subscription">
              <ellipse style="fill: rgba(255, 64, 0, 0.7)" rx="71.5" ry="71.5" cx="71.5" cy="71.5"></ellipse>
            </svg>
            <div id="btn6" @click="subscription">
              <span>BimiStock<br />を今すぐ<br />はじめる</span>
            </div>
          </div>
        </div>
        <div v-if="size === 2">
          <div v-if="isLoggedIn === false" id="icon3">
            <v-badge color="#41AB55" overlap :content="count" :value="count > 0">
              <img src="@/views/images/home/グループ 3266@2x.png" height="33px" @click="login" />
            </v-badge>
          </div>
          <div v-if="isLoggedIn === true" id="icon4">
            <v-badge color="#41AB55" overlap :content="count" :value="count > 0">
              <img src="@/views/images/home/グループ 3266@2x.png" height="33px" @click="cart" />
            </v-badge>
          </div>
          <v-menu offset-y min-width="150px" absolute>
            <template #activator="{ on, attrs }">
              <div>
                <svg v-if="isLoggedIn === false" id="square2" class="pointer" @click="login">
                  <rect style="fill: rgba(255, 64, 0, 0.7)" x="0" y="30" rx="15" ry="15" width="100" height="40"></rect>
                </svg>
                <div v-if="isLoggedIn === false" id="login2">
                  <span @click="login">ログイン</span>
                </div>
                <div v-if="isLoggedIn === true" id="logout2">
                  <v-icon color="#ff6e40" x-large v-bind="attrs" v-on="on"> mdi-account-outline </v-icon>
                </div>
                <div v-if="isLoggedIn === true" id="name2">
                  <span>{{ showUsername }}</span>
                </div>
              </div>
            </template>
            <v-list style="text-align: right">
              <v-list-item v-for="(item, index) in items" :key="index" @click="selectedPage(item)">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div v-if="isLoggedIn === false" style="margin-top: 57px; margin-left: 97px">
            <svg id="circle2" class="pointer" @click="subscription">
              <ellipse style="fill: rgba(255, 64, 0, 0.7)" rx="81.5" ry="81.5" cx="81.5" cy="81.5"></ellipse>
            </svg>
            <div id="btn5" @click="subscription">
              <span>BimiStock<br />を今すぐ<br />はじめる</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    scrollController: 0,
    size: 0,
    showUsername: '',
    navItems: [
      {
        text: 'BimiStockについて',
        path: 'title2',
        mark: 1,
        index: 0,
        class: 'btn',
      },
      {
        text: 'お試しセット',
        path: '/setlist',
        index: 1,
        class: 'btn',
      },
      {
        text: '定期便商品',
        path: '/subscriptionlist',
        index: 2,
        class: 'btn',
      },
      {
        text: 'アレンジレシピ',
        path: 'title5',
        mark: 2,
        index: 3,
        class: 'btn',
      },
      {
        text: '防災・減災',
        path: 'title7',
        mark: 3,
        index: 4,
        class: 'btn',
      },
      {
        text: 'FAQ',
        path: '/faq',
        index: 5,
        class: 'btn',
      },
    ],
    items: [
      {
        text: '注文履歴',
        path: '/history',
      },
      {
        text: '定期便',
        path: '/schedule',
      },
      {
        text: 'お届け先',
        path: '/address',
      },
      {
        text: '会員情報',
        path: '/account',
      },
      {
        text: '支払い情報',
        path: '/payment',
      },
      {
        name: 'logout',
        text: 'ログアウト',
        path: '/home',
      },
    ],
  }),
  computed: {
    ...mapState('auth', ['isLoggedIn', 'username']),
    ...mapState('cartStore', ['count', 'cartList']),
  },
  watch: {
    $route: 'getPath',
    username() {
      this.updateUsername()
    },
  },
  created() {
    console.log('nav created')
    this.getPath()
    this.updateUsername()
  },
  mounted() {
    window.addEventListener('load', this.getWidth())
    window.addEventListener('resize', this.getWidth)

    window.addEventListener('scroll', this.controllScroll)
  },
  methods: {
    ...mapMutations('cartStore', ['clearCart']),
    ...mapMutations('auth', ['clearInfo']),

    getWidth() {
      if (window.innerWidth <= 1466) {
        this.size = 1
      } else {
        this.size = 2
      }
    },
    updateUsername() {
      if (this.username.length > 6) {
        this.showUsername = `${this.username.substring(0, 6)}...様`
      } else if (this.username.length > 0) {
        this.showUsername = `${this.username}様`
      } else {
        this.showUsername = ''
      }
    },
    handleScroll() {
      this.navItems.forEach((item, i) => {
        this.navItems[i].class = 'btn'
      })
    },
    getPath() {
      this.handleScroll()
      if (this.$route.name === 'home') {
        if (this.$route.query.Anchor === '1') {
          this.navItems[0].class = 'btn0'
          this.scrollController = window.scrollY
        }
        if (this.$route.query.Anchor === '2') {
          this.navItems[3].class = 'btn0'
          this.scrollController = window.scrollY
        }
        if (this.$route.query.Anchor === '3') {
          this.navItems[4].class = 'btn0'
          this.scrollController = window.scrollY
        }
      }
      if (this.$route.name !== 'home') {
        if (this.$route.name === 'setlist') {
          this.navItems[1].class = 'btn0'
        }
        if (this.$route.name === 'set') {
          this.navItems[1].class = 'btn0'
        }
        if (this.$route.name === 'subscriptionlist') {
          this.navItems[2].class = 'btn0'
        }
        if (this.$route.name === 'subscription') {
          this.navItems[2].class = 'btn0'
        }
        if (this.$route.name === 'recipe') {
          this.navItems[3].class = 'btn0'
        }
        if (this.$route.name === 'faq') {
          this.navItems[5].class = 'btn0'
        }
      }
    },
    controllScroll() {
      if (this.$route.name === 'home') {
        if (window.scrollY !== this.scrollController) {
          this.handleScroll()
        }
      }
    },
    navAction(val) {
      console.log('navAction', this.$route.name, val)
      if (this.$route.name === 'home') {
        if (val.index === 0 || val.index === 3 || val.index === 4) {
          this.$router.push({
            path: '/home',
            query: {
              Anchor: val.mark,
            },
          })
          const element = document.getElementById(val.path)
          element.scrollIntoView()
          this.handleScroll()
          this.navItems[val.index].class = 'btn0'
          this.scrollController = window.scrollY
        } else {
          this.$router.push(val.path)
        }
      }
      if (this.$route.name !== 'home') {
        if (val.index === 0 || val.index === 3 || val.index === 4) {
          this.$router.push({
            path: '/',
            query: {
              Anchor: val.mark,
            },
          })
        } else {
          this.$router.push(val.path)
        }
      }
    },
    clickLogo() {
      if (this.$route.name === 'home') {
        this.scroll('title1')
      }
      if (this.$route.name !== 'home') {
        this.backHome()
      }
    },
    scroll(key) {
      const element = document.getElementById(key)
      element.scrollIntoView()
    },
    backHome() {
      this.$router.push({
        path: '/',
        query: {
          Anchor: 0,
        },
      })
    },
    selectedPage(item) {
      if (item.name === 'logout') {
        this.clearCart()
        this.$store.dispatch('auth/logout')
        this.clearInfo()
      }
      // eslint-disable-next-line no-unused-expressions
      this.$route.path !== item.path && this.$router.push(item.path)
    },
    set() {
      this.$router.push('/setlist')
    },
    subscription() {
      this.$router.push('/subscriptionlist')
    },
    faq() {
      this.$router.push('/faq')
    },
    cart() {
      this.$router.push('/cart')
    },
    login() {
      this.$router.push('/login')
    },
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>

<style scoped>
#toolbar {
  width: 100%;
  height: 0;
  margin: 0 auto;
}
#img :hover {
  cursor: pointer;
}
.btn0 {
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #cbcbcb;
}
.btn {
  margin-left: 15px;
  margin-right: 15px;
  text-align: left;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: rgba(255, 104, 47, 1);
}

.button1 {
  margin-top: 35px;
  margin-left: 160px;
  width: 1366px;
}
.button2 {
  margin-top: 35px;
  margin-left: 200px;
  width: 1366px;
}
.button1 :hover {
  cursor: pointer;
  color: #cbcbcb;
}
.button2 :hover {
  cursor: pointer;
  color: #cbcbcb;
}
#icon1 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 35px;
  right: 310px;
}
#icon1 :hover {
  cursor: pointer;
}
#icon2 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 35px;
  right: 100px;
}
#icon2 :hover {
  cursor: pointer;
}
#icon3 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 35px;
  right: 358px;
}
#icon3 :hover {
  cursor: pointer;
}
#icon4 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 35px;
  right: 100px;
}
#icon4 :hover {
  cursor: pointer;
}
#square1 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 0px;
  right: -16px;
}
#square2 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 3px;
  right: 30px;
}
#login1 {
  top: -36px;
  right: 215px;
  position: absolute;
  margin-top: 77px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
}
#login1 :hover {
  cursor: pointer;
}
#name1 {
  top: -5px;
  right: 40px;
  position: absolute;
  margin-top: 77px;
  text-align: right;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 16px;
}
#logout1 {
  top: -46px;
  right: 35px;
  position: absolute;
  margin-top: 77px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 16px;
}
#logout1 :hover {
  cursor: pointer;
}
#login2 {
  top: -38px;
  right: 248px;
  position: absolute;
  margin-top: 77px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
}
#login2 :hover {
  cursor: pointer;
}
#name2 {
  top: -5px;
  right: 35px;
  position: absolute;
  margin-top: 77px;
  text-align: right;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 16px;
}
#logout2 {
  top: -46px;
  right: 32px;
  position: absolute;
  margin-top: 77px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 16px;
}
#logout2 :hover {
  cursor: pointer;
}
#circle1 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  top: 0px;
  right: 20px;
  margin-top: 37px;
}
#circle2 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  top: 0px;
  right: 40px;
  margin-top: 37px;
}
#btn5 {
  top: -5px;
  right: 68px;
  position: absolute;
  margin-top: 77px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 21px;
  color: rgba(255, 255, 255, 1);
}
#btn5 :hover {
  cursor: pointer;
}
#btn6 {
  top: 68px;
  right: 63px;
  position: absolute;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 19px;
  color: rgba(255, 255, 255, 1);
}
#btn6 :hover {
  cursor: pointer;
}
.pointer :hover {
  cursor: pointer;
}
</style>
