<template>
  <v-dialog v-model="dialogShippingFee" width="1200px" persistent>
    <v-card class="pb-3">
      <div class="px-6 pt-6">
        <div class="d-flex justify-center" style="font-weight: bold; font-size: 20px">
          <span>配送手配・送料について</span>
        </div>
        <v-row class="mt-2 mb-0">
          <v-col>
            <div>
              <span>【宅配便業者】佐川急便</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-0 my-0">
          <v-col>
            <div>
              <span>お試しセットは送料無料</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-0 my-0">
          <v-col>
            <div>
              <span>定期便の送料は下記の通り</span>
            </div>
          </v-col>
          <v-col>
            <div class="d-flex justify-end">
              <span>（金額：円／税込）</span>
            </div>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="13"
          hide-default-footer
          class="resizable-column"
          disable-sort
          style="word-break: keep-all"
        >
          <template #[`item.size80`]="{ item }">
            <label>{{ item.size80.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
          </template>
          <template #[`item.size100`]="{ item }">
            <label>{{ item.size100.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
          </template>
        </v-data-table>
      </div>
      <div class="d-flex justify-center mt-3">
        <v-btn color="#ff6e40" width="80px" depressed @click="closeDialog">
          <span style="color: #fff">OK</span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog'],
  data: () => ({
    dialogShippingFee: '',
    headers: [
      {
        text: '地帯名称',
        value: 'area',
        width: '40%',
        align: 'left',
      },
      {
        text: '南九州',
        value: 'area1',
        width: '5%',
        align: 'right',
      },
      {
        text: '北九州',
        value: 'area2',
        width: '5%',
        align: 'right',
      },
      {
        text: '四国',
        value: 'area3',
        width: '5%',
        align: 'right',
      },
      {
        text: '中国',
        value: 'area4',
        width: '5%',
        align: 'right',
      },
      {
        text: '関西',
        value: 'area5',
        width: '5%',
        align: 'right',
      },
      {
        text: '北陸',
        value: 'area6',
        width: '5%',
        align: 'right',
      },
      {
        text: '東海',
        value: 'area7',
        width: '5%',
        align: 'right',
      },
      {
        text: '信越',
        value: 'area8',
        width: '5%',
        align: 'right',
      },
      {
        text: '関東',
        value: 'area9',
        width: '5%',
        align: 'right',
      },
      {
        text: '南東北',
        value: 'area10',
        width: '5%',
        align: 'right',
      },
      {
        text: '北東北',
        value: 'area11',
        width: '5%',
        align: 'right',
      },
      {
        text: '北海道',
        value: 'area12',
        width: '5%',
        align: 'right',
      },
    ],
    items: [
      {
        area: '県名',
        area1: '熊本県 宮崎県 鹿児島県',
        area2: '福岡県 佐賀県 大分県 長崎県',
        area3: '徳島県 香川県 高知県 愛媛県',
        area4: '岡山県 広島県 山口県 鳥取県 島根県',
        area5: '京都府 滋賀県 奈良県 大阪府 兵庫県 和歌山県',
        area6: '富山県 石川県 福井県',
        area7: '静岡県 愛知県 岐阜県 三重県',
        area8: '長野県 新潟県',
        area9: '東京都 神奈川県 千葉県 埼玉県 茨城県 群馬県 栃木県 山梨県',
        area10: '宮城県 山形県 福島県',
        area11: '青森県 秋田県 岩手県',
        area12: '北海道',
      },
      {
        area: '1人分(80サイズ)',
        area1: 605,
        area2: 605,
        area3: 583,
        area4: 583,
        area5: 572,
        area6: 572,
        area7: 550,
        area8: 572,
        area9: 550,
        area10: 572,
        area11: 583,
        area12: 616,
      },
      {
        area: '2人分(120サイズ)',
        area1: '1,078',
        area2: '1,078',
        area3: 990,
        area4: 990,
        area5: 946,
        area6: 946,
        area7: 825,
        area8: 946,
        area9: 803,
        area10: 946,
        area11: 990,
        area12: '1,110',
      },
      {
        area: '3人分(120サイズ)',
        area1: '1,078',
        area2: '1,078',
        area3: 990,
        area4: 990,
        area5: 946,
        area6: 946,
        area7: 825,
        area8: 946,
        area9: 803,
        area10: 946,
        area11: 990,
        area12: '1,110',
      },
      {
        area: '4人分(120+80サイズ)',
        area1: '1,683',
        area2: '1,683',
        area3: '1,573',
        area4: '1,573',
        area5: '1,518',
        area6: '1,518',
        area7: '1,375',
        area8: '1,518',
        area9: '1,353',
        area10: '1,518',
        area11: '1,573',
        area12: '1,716',
      },
      {
        area: '5・6人分(120+120サイズ)',
        area1: '2,156',
        area2: '2,156',
        area3: '1,980',
        area4: '1,980',
        area5: '1,892',
        area6: '1,892',
        area7: '1,650',
        area8: '1,892',
        area9: '1,606',
        area10: '1,892',
        area11: '1,980',
        area12: '2,200',
      },
      {
        area: '7・8人分(80+120+120サイズ)',
        area1: '2,761',
        area2: '2,761',
        area3: '2,563',
        area4: '2,563',
        area5: '2,464',
        area6: '2,464',
        area7: '2,200',
        area8: '2,464',
        area9: '2,156',
        area10: '2,464',
        area11: '2,563',
        area12: '2,816',
      },
      {
        area: '9・10人分(120+120+120サイズ)',
        area1: '3,234',
        area2: '3,234',
        area3: '2,970',
        area4: '2,970',
        area5: '2,838',
        area6: '2,838',
        area7: '2,475',
        area8: '2,838',
        area9: '2,409',
        area10: '2,838',
        area11: '2,970',
        area12: '3,300',
      },

    ],
  }),
  watch: {
    dialog() {
      this.dialogShippingFee = this.dialog
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style scoped>
/* table th + th { border-left:1px solid #dddddd; }
table td + td { border-left:1px solid #dddddd; } */
.resizable-column {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.resizable-column /deep/ th + th {
  border-left: 1px solid #dddddd;
}
.resizable-column /deep/ td + td {
  border-left: 1px solid #dddddd;
}
</style>
