<template>
  <v-app-bar app color="rgba(0,0,0,0)" flat height="0" min-width="430px">
    <div>
      <v-img
        :src="require('../assets/images/homemobile/n_3262.png')"
        max-height="40px"
        max-width="170px"
        style="margin-top: 60px; margin-left: 5px"
        class="pointer"
        @click="clickMenu('title1', 0)"
      ></v-img>
      <div v-show="!showMenu" class="x3295" @click.prevent="openMenu">
        <div class="x3295-item"></div>
        <div class="x839"></div>
        <div class="x3295-item"></div>
      </div>
      <div v-show="showMenu">
        <img id="bg" :src="images.x841" />
        <div id="mark1" class="mark" @click="clickMenu('title2', 1)">
          <span>BimiStockについて</span>
        </div>
        <div id="mark2" class="mark" @click="set">
          <span>お試しセット</span>
        </div>
        <div id="mark3" class="mark" @click="subscriptions">
          <span>定期便の商品</span>
        </div>
        <div id="mark4" class="mark" @click="clickMenu('title5', 2)">
          <span>アレンジレシピ</span>
        </div>
        <div id="mark5" class="mark" @click="clickMenu('title9', 3)">
          <span>防災・減災</span>
        </div>
        <div id="mark6" class="mark" @click="faq">
          <span>FAQ</span>
        </div>
        <div id="mark8" class="mark" @click="closeMenu">
          <img style="width: 16px; height: 24px" :src="images.image42" />
        </div>
      </div>
      <div v-if="isLoggedIn === true" id="icon1">
        <v-badge color="#41AB55" overlap :content="count" :value="count > 0">
          <img src="@/views/images/home/グループ 3266@2x.png" height="30px" @click="cart" />
        </v-badge>
      </div>
      <div v-if="isLoggedIn === false" id="icon2">
        <v-badge color="#41AB55" overlap :content="count" :value="count > 0">
          <img src="@/views/images/home/グループ 3266@2x.png" height="30px" @click="login" />
        </v-badge>
      </div>
      <div v-if="isLoggedIn === false">
        <svg id="circle1">
          <ellipse style="fill: rgba(255, 64, 0, 0.7)" rx="48" ry="48" cx="48" cy="48"></ellipse>
        </svg>
        <div id="btn7" @click="subscriptions">
          <span>BimiStock<br />を今すぐ<br />はじめる</span>
        </div>
      </div>
      <div v-if="isLoggedIn === true">
        <v-menu offset-y z-indx="100" min-width="120px" absolute>
          <template #activator="{ on, attrs }">
            <div>
              <v-icon id="account" color="#ff6e40" large v-bind="attrs" v-on="on"> mdi-account-outline </v-icon>
              <div v-if="isLoggedIn === true" id="accountLogin">
                <span>{{ showUsername }}</span>
              </div>
            </div>
          </template>
          <v-list style="text-align: right">
            <v-list-item v-for="(item, index) in items" :key="index" @click="selectedPage(item)">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <svg v-if="isLoggedIn === false" id="square">
        <rect style="fill: rgba(255, 64, 0, 0.7)" x="0" y="35" rx="8" ry="8" width="75" height="30"></rect>
      </svg>
      <div v-if="isLoggedIn === false" id="login">
        <span @click="login">ログイン</span>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations, mapActions } from 'vuex'
/* eslint-disable */

const bimiHomeNavData = {
  x841: require('@/img/file----841@1x.png'),
  image42: require('@/img/file-@1x.png'),
}

export default {
  data: () => ({
    images: {
      ...bimiHomeNavData,
    },
    showMenu: false,
    showUsername: '',
    items: [
      {
        text: '注文履歴',
        path: '/history',
      },
      {
        text: '定期便',
        path: '/schedule',
      },
      {
        text: 'お届け先',
        path: '/address',
      },
      {
        text: '会員情報',
        path: '/account',
      },
      {
        text: '支払い情報',
        path: '/payment',
      },
      {
        name: 'logout',
        text: 'ログアウト',
        path: '/',
      },
    ],
  }),
  computed: {
    ...mapState('auth', ['isLoggedIn', 'username']),
    ...mapState('cartStore', ['count', 'cartList']),
  },
  watch: {
    username() {
      this.updateUsername()
    },
  },
  created() {
    this.updateUsername()
  },
  methods: {
    ...mapMutations('cartStore', ['clearCart']),
    updateUsername() {
      if (this.username.length > 6) {
        this.showUsername = `${this.username.substring(0, 6)}...様`
      } else if (this.username.length > 0) {
        this.showUsername = `${this.username}様`
      } else {
        this.showUsername = ''
      }
    },
    clickMenu(val1, val2) {
      if (this.$route.name === 'home' || this.$route.name === 'homemobile') {
        this.scroll(val1)
      }
      if (this.$route.name !== 'home' && this.$route.name !== 'homemobile') {
        this.toOpen(val2)
      }
    },
    scroll(key) {
      const element = document.getElementById(key)
      element.scrollIntoView()
    },
    toOpen(key) {
      this.$router.push({
        path: '/',
        query: {
          Anchor: key,
        },
      })
    },
    login() {
      this.$router.push('/login')
    },
    set() {
      this.$router.push('/setlist')
    },
    subscriptions() {
      this.$router.push('/subscriptionlist')
    },
    faq() {
      this.$router.push('/faq')
    },
    cart() {
      this.$router.push('/cart')
    },
    selectedPage(item) {
      if (item.name === 'logout') {
        this.clearCart()
        this.$store.dispatch('auth/logout')
      }
      this.$router.push(item.path)
    },
    openMenu() {
      this.showMenu = true
    },
    closeMenu() {
      this.showMenu = false
    },
    menuClick() {
      this.openMenu()
    },
  },
}
</script>

<style>
#square {
  /* mix-blend-mode: multiply; */
  position: absolute;
  overflow: visible;
  top: -12px;
  right: 115px;
  width: 75px;
  height: 30px;
}
#login {
  top: -50px;
  right: 126px;
  position: absolute;
  margin-top: 77px;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}
#login :hover {
  cursor: pointer;
}
#icon1 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 20px;
  right: 65px;
}
#icon2 {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 22px;
  right: 205px;
}
#account {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 18px;
  right: 20px;
}
#accountLogin {
  mix-blend-mode: multiply;
  position: absolute;
  overflow: visible;
  top: 55px;
  right: 25px;
  text-align: right;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 12px;
}
#bg {
  height: 215px;
  left: 15px;
  object-fit: cover;
  position: absolute;
  top: 82px;
  width: 225px;
}
.mark {
  position: fixed;
  left: 42px;
  height: 24px;
  color: #ff6e40;
  font-weight: bold;
  font-size: 16px;
}
.mark :hover {
  cursor: pointer;
}
#mark1 {
  top: 95px;
  width: 148px;
}
#mark2 {
  top: 122px;
  width: 96px;
}
#mark3 {
  top: 150px;
  width: 96px;
}
#mark4 {
  top: 177px;
  width: 112px;
}
#mark5 {
  top: 203px;
  width: 80px;
}
#mark6 {
  top: 230px;
  width: 32px;
}
#mark8 {
  top: 260px;
  width: 16px;
  height: 24px;
}
.log {
  height: 24px;
  left: 42px;
  object-fit: cover;
  position: fixed;
  top: 230px;
  width: 180px;
}

.x3295 {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  left: 21px;
  min-height: 17px;
  position: fixed;
  top: 64px;
  width: 39px;
}
.x839 {
  background-color: #ff6e40;
  height: 3px;
  width: 39px;
}

.x3295-item {
  background-color: #ff6e40;
  height: 2px;
  width: 39px;
}

#circle1 {
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  overflow: visible;
  width: 86px;
  height: 86px;
  top: 21px;
  right: 21px;
}
#btn7 {
  top: 39px;
  right: 24px;
  position: absolute;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  z-index: 100;
}
#btn7 :hover {
  cursor: pointer;
}
.pointer :hover {
  cursor: pointer;
}
</style>
