import agent from '../../api/agent'

const member = {
  namespaced: true,
  state: {
    memberList: [],
    member: undefined,
    selectedMember: undefined,
  },
  mutations: {
    updateMemberList(state, value) {
      state.memberList = value
    },
    updateSelectedMember(state, value) {
      state.selectedMember = value
    },
  },
  actions: {
    async loadMemberList(context) {
      const result = await agent.Members.all()
      context.commit('updateMemberList', result.data)
    },

    async createMember(context, members) {
      await agent.Members.add(members)
    },

    async registerMember(context, members) {
      const obj = {
        ...members,
      }
      await agent.Members.register(obj)
    },

    async loadMember(context, id) {
      const result = await agent.Members.detail(id)
      context.commit('updateSelectedMember', result.data)
    },

    async editMember(context, members) {
      const obj = {
        ...members,
      }
      await agent.Members.update(obj)
    },
  },
}

export default member
