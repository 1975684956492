import Vue from 'vue'
import Vuelidate from 'vuelidate'
import {
  init,
} from '@/api/agent'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify/index'

init(store, router)
Vue.config.productionTip = false
Vue.use(Vuelidate)

router.afterEach(() => {
  window.scrollTo(0, 0)
})

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
// global handle gmo multipayment result
const GMOMultipaymentResult = res => {
  vue.$emit('gmo_multipayment_result', res)
}
window.GMO_Multipayment_result = GMOMultipaymentResult
