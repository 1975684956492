import axios from 'axios'
import fileDownload from 'js-file-download'

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
})
const responseBody = response => response.data

// eslint-disable-next-line consistent-return
export function init(store, router) {
  HTTP.interceptors.request.use(
    config => {
      // config.headers = getHeader()
      const accessToken = store.state.auth.token

      // eslint-disable-next-line no-param-reassign
      if (accessToken) config.headers.Authorization = `${accessToken}`

      return config
    },
    error => Promise.reject(error),
  )
  HTTP.interceptors.response.use(
    async response => {
      const accessToken = response.headers.authorization
      if (accessToken !== undefined) {
        store.dispatch('auth/updateToken', accessToken)

        // localStorage.setItem('accessToken', accessToken)
      }

      // if (response.data.code === '401') {
      //   // 重定向到登录页
      //   router.replace({
      //     // path: '/auth/login',
      //     name: 'login',
      //     query: {
      //       redirect: router.currentRoute.fullPath,
      //     },
      //   })
      // }

      return response
    },
    error => {
      const { status, config } = error.response
      const accessToken = store.state.auth.token

      // const accessToken = localStorage.getItem('accessToken')
      switch (status) {
        case 422:
          break
        case 401:
          if (config.headers.Authorization && accessToken !== config.headers.Authorization) {
            config.headers.Authorization = accessToken

            return axios(config)
          }
          store.dispatch('auth/clearLoginStatus')
          if (router.currentRoute.meta.auth) {
            router.replace({
              // path: '/auth/login',
              name: 'login',
              query: {
                redirect: router.currentRoute.fullPath,
              },
            })
          }

          // 重定向到登录页
          // router.replace({
          //   // path: '/auth/login',
          //   name: 'login',
          //   query: {
          //     redirect: router.currentRoute.fullPath,
          //   },
          // })

          // router.push({ name: 'auth-login' })
          break
        default:
          break
      }

      return Promise.reject(error)
    },
  )
  if (store.method !== undefined) {
    return HTTP(store)
  }
}
/* eslint-disable */
const Members = {
  all: query =>
    HTTP.get('/members', {
      params: query,
    }).then(responseBody),
  add: member => HTTP.post('/members', member),
  update: member => HTTP.put(`/members/${member.id}`, member),
  detail: id => HTTP.get(`/members/${id}`).then(responseBody),
  register: member => HTTP.put(`/register/${member.mbr_id}`, member),
}
const Sets = {
  all: query =>
    HTTP.get('/sets', {
      params: query,
    }).then(responseBody),
  detail: id => HTTP.get(`/sets/${id}`).then(responseBody),

  items: id => HTTP.get(`/setitems/${id}`).then(responseBody),
}
const Subscriptions = {
  all: query =>
    HTTP.get('/subscriptions', {
      params: query,
    }).then(responseBody),
  detail: id => HTTP.get(`/subscriptions/${id}`).then(responseBody),

  items: id => HTTP.get(`/subscriptionitems/${id}`).then(responseBody),
}
const Items = {
  all: query =>
    HTTP.get('user/items', {
      params: query,
    }).then(responseBody),
  allCodes: query =>
    HTTP.get('/itemcodes', {
      params: query,
    }).then(responseBody),
  add: item => HTTP.post('/items', item),
  update: item => HTTP.put(`/items/${item.id}`, item),
  detail: id => HTTP.get(`/user/items/${id}`).then(responseBody),
  delete: id => HTTP.delete(`/items/${id}`),
}
const Shippingfee = {
  // detail: pref_code => HTTP.get(`/shipping-fee/${pref_code}`, ).then(responseBody),
  detail: item => HTTP.post('/shipping-fee', item),
}
const Order = {
  add: order => HTTP.post('/user/order', order),
  all: query =>
    HTTP.get('/user/orders', {
      params: query,
    }).then(responseBody),
  detail: id => HTTP.get(`/user/order/${id}`).then(responseBody),
  cancel: id => HTTP.delete(`/user/order/${id}`),
}

const Schedule = {
  all: query =>
    HTTP.get('/user/subscriptionschedules', {
      params: query,
    }).then(responseBody),
  skip: id => HTTP.put(`/user/skipsubscription/${id}`),
  undoSkip: id => HTTP.put(`/user/undoskip/${id}`),
  cancel: id => HTTP.put(`/user/cancelsubscription/${id}`),
  undoCancel: id => HTTP.put(`/user/undocancel/${id}`),
}

const TrailSet = {
  add: trailSet => HTTP.post('/trail-set', trailSet),
}

const Coupons = {
  getInfo: query =>
    HTTP.get('/user/couponinfo', {
      params: query,
    }).then(responseBody),
}

const Addresses = {
  all: query =>
    HTTP.get('/user/addresses', {
      params: query,
    }).then(responseBody),
  add: address => HTTP.post('/user/addresses', address),
  update: address => HTTP.put(`/user/addresses/${address.addr_id}`, address),
  detail: id => HTTP.get(`/user/addresses/${id}`).then(responseBody),
  delete: id => HTTP.delete(`/user/addresses/${id}`),
}
const Auth = {
  login: user => HTTP.post('/login', user),
  logout: () => HTTP.post('/logout'),
  info: () => HTTP.get('/user/info'),
  cancelMembership: () => HTTP.put('/user/cancelmembership'),
  changeAddress: address => HTTP.put('/change-address', address),
  changeMobile: mobile => HTTP.put('/change-mobile', mobile),
  changePassword: password => HTTP.put('/change-password', password),
  changeDescription: description => HTTP.put('/change-description', description),
  resetPassword: password => HTTP.post('reset-password', password),
  forgotPassword: password => HTTP.post('/forgot-password', password),
  checkToken: credential => HTTP.post('/check-reset', credential),

  // authInfo: () => axios.get('/auth/user-profile'),
  // logout: () => axios.post('/auth/logout'),
  // uploadPhoto: img => {
  //   const formData = new FormData()
  //   formData.append('material_img', img)

  //   return axios
  //     .post('/materials/photo', formData, {
  //       headers: { 'Content-type': 'multipart/form-data' },
  //     })
  //     .then(responseBody)
  // },
  // update: material => axios.put(`/materials/${material.id}`, material),
  // delete: id => axios.delete(`/materials/${id}`),
  // detail: id => axios.get(`/materials/${id}`).then(responseBody),
}

const Register = {
  sendVerifyMail: member => HTTP.post('/emailverify', member),
  getMemberInfo: uuid => HTTP.get(`/emailverify/${uuid}`).then(responseBody),
}
const Card = {
  detail: () => HTTP.get('/card').then(responseBody),
  modify: cardInfo => HTTP.post('/card', cardInfo),
  delete: () => HTTP.delete('/card'),
}

const Inquiry = {
  add: inquiry => HTTP.post('/inquiries', inquiry),
}

const Delivery = {
  detail: id =>
    HTTP.get(`/user/delivery/${id}`, {
      responseType: 'blob',
    }).then(res => {
      const content = res.headers['content-disposition']
      let filename = 'delivery.pdf'
      if (content) {
        let name1 = content.match(/filename=(.*);/)[1]
        let name2 = content.match(/filename\*=(.*)/)[1]
        name1 = decodeURIComponent(name1.replaceAll('"', ''))
        name2 = decodeURIComponent(name2.substring(7))
        filename = name2 || name1
      }

      fileDownload(res.data, filename)
    }),
}

const agent = {
  Members,
  Sets,
  Subscriptions,
  Items,
  Shippingfee,
  Order,
  Schedule,
  TrailSet,
  Coupons,
  Addresses,
  Auth,
  Register,
  Card,
  Inquiry,
  Delivery,
}

export default agent
