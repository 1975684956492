import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import {
  bimiHomeData,
} from '../dataPC0116'

Vue.use(VueRouter)

function isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  )

  return flag
}

let routes = []
if (process.env.VUE_APP_MAINTAIN === 'true') {
  routes = [
    {
      path: '/*',
      component: () => import('@/views/Maintain.vue'),
      meta: {
        auth: false,
      },
    },
  ]
} else {
  routes = [
    {
      path: '/',
      redirect: () => {
        if (isMobile()) {
          return {
            name: 'homemobile',
          }
        }

        return {
          name: 'home',
        }
      },
    },
    {
      path: '/home',
      name: 'home',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/HomeClient.vue'),
      props: {
        ...bimiHomeData,
      },
    },
    {
      path: '/homemobile',
      name: 'homemobile',
      meta: {
        layout: 'blank',
        auth: false,
      },
      component: () => import('@/views/HomeMobile.vue'),
    },
    {
      path: '/recipe',
      name: 'recipe',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/Recipe.vue'),
    },
    {
      path: '/information',
      name: 'information',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/Information.vue'),
    },
    {
      path: '/privacy',
      name: 'privacy',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/Privacy.vue'),
    },
    {
      path: '/company',
      name: 'company',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/Company.vue'),
    },
    {
      path: '/faq',
      name: 'faq',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/Faq.vue'),
    },
    {
      path: '/privacy',
      name: 'privacy',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/Privacy.vue'),
    },
    {
      path: '/company',
      name: 'company',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/Company.vue'),
    },
    {
      path: '/set/:id',
      name: 'set',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/set/Set.vue'),
    },
    {
      path: '/set',
      name: 'set',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/set/SetList.vue'),
    },

    {
      path: '/setlist',
      name: 'setlist',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/set/SetList.vue'),
    },

    {
      path: '/subscriptionlist',
      name: 'subscriptionlist',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/subscription/SubscriptionList.vue'),
    },
    {
      path: '/subscription/:id',
      name: 'subscription',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/subscription/Subscription.vue'),
    },
    {
      path: '/subscription',
      name: 'subscription',
      meta: {
        layout: 'content',
        auth: false,
      },
      component: () => import('@/views/subscription/SubscriptionList.vue'),
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('@/views/cart/Cart.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/pay',
      name: 'pay',
      component: () => import('@/views/cart/Pay.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/address',
      name: 'address',
      component: () => import('@/views/account/Address.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/addressedit',
      name: 'addressedit',
      component: () => import('@/views/cart/AddressEdit.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/addresscreate',
      name: 'addressecreate',
      component: () => import('@/views/cart/AddressCreate.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/history/History.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: () => import('@/views/schedule/Schedule.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: () => import('@/views/history/Cancel.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/account/Account.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/changemobile',
      name: 'changemobile',

      // disable eslint
      component: () => import('@/views/account/MobileEdit.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/changeaddress',
      name: 'changeaddress',
      component: () => import('@/views/account/AddressEdit.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/changedescription',
      name: 'changedescription',
      component: () => import('@/views/account/DescriptionEdit.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/views/payment/Payment.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },
    {
      path: '/changepassword',
      name: 'changepassword',
      component: () => import('@/views/account/PasswordEdit.vue'),
      meta: {
        layout: 'content',
        auth: true,
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/Login.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/login/Register.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/login/ForgotPassword.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/login/ResetPassword.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/info/:id',
      name: 'info',
      component: () => import('@/views/login/Info.vue'),
      meta: {
        layout: 'content',
        auth: false,
      },
    },
    {
      path: '/maintenance',
      component: () => import('@/views/Maintain.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/*',
      component: () => import('@/views/Error404.vue'),
      meta: {
        auth: false,
      },
    },
  ]
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach(async (to, from, next) => {
  if ((to.name === 'login' || to.name === 'register') && store.state.auth.isLoggedIn) {
    next('/')
  }
  switch (to.name) {
    case 'home':
      // eslint-disable-next-line no-unused-expressions
      isMobile() ? next('/homemobile') : next()
      break
    case 'homemobile':
      // eslint-disable-next-line no-unused-expressions
      isMobile() ? next() : next('/home')
      break
    default:
      break
  }
  if (to.matched.some(record => record.meta.auth === undefined || record.meta.auth)) {
    // 判断该路由是否需要登录权限

    if (store.state.auth.isLoggedIn === true) {
      next()
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      })
    }
  } else {
    next()
  }
})
export default router
