<template>
  <v-dialog v-model="dialog" width="900px" persistent>
    <v-card class="px-6 py-3">
      <div class="mt-6 mb-3" style="font-size: 20px; font-weight: bold">
        <span>お問い合わせフォーム</span>
      </div>
      <template v-if="apierror.status == 'error'">
        <div v-for="(msg, index) of apierror.messages" :key="index">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">{{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-spacer></v-spacer>
      <div class="py-6">
        <v-spacer></v-spacer>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="3">
            <div>
              <span>お名前</span>
              <span
                class="ml-1 pr-1"
                style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
              >
                必須</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="d-flex">
              <v-text-field
                v-model="$v.Form.faq_sei.$model"
                :error-messages="seiErrors"
                placeholder="姓"
                hide-details="auto"
                outlined
                dense
                color="#ff6e40"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="$v.Form.faq_mei.$model"
              :error-messages="meiErrors"
              placeholder="名"
              hide-details="auto"
              outlined
              dense
              color="#ff6e40"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="3">
            <div>
              <span>フリガナ</span>
              <!-- <span
                class="ml-1 pr-1"
                style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                >必須</span
              > -->
            </div>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="$v.Form.faq_sei_kana.$model"
              :error-messages="seiKanaErrors"
              placeholder="セイ"
              hide-details="auto"
              outlined
              dense
              color="#ff6e40"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <div class="d-flex">
              <v-text-field
                v-model="$v.Form.faq_mei_kana.$model"
                :error-messages="meiKanaErrors"
                placeholder="メイ"
                hide-details="auto"
                outlined
                dense
                color="#ff6e40"
              ></v-text-field>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="3">
            <div>
              <span>電話番号</span>
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="$v.Form.faq_tel.$model"
              :error-messages="mobileErrors"
              placeholder="09012345678"
              hide-details="auto"
              outlined
              dense
              color="#ff6e40"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="3">
            <div>
              <span>メールアドレス</span>
              <span
                class="ml-1 pr-1"
                style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
              >
                必須</span>
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="$v.faq_email.$model"
              :error-messages="emailErrors"
              placeholder="メールアドレス"
              hide-details="auto"
              outlined
              dense
              color="#ff6e40"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="3">
            <div>
              <span>メール確認</span>
              <span
                class="ml-1 pr-1"
                style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
              >
                必須</span>
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="$v.confirm_mail.$model"
              placeholder="メールアドレス（確認用）"
              :error-messages="confirmMailErrors"
              hide-details="auto"
              outlined
              dense
              color="#ff6e40"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="3">
            <div>
              <span>お問い合わせ内容</span>
              <span
                class="ml-1 pr-1"
                style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
              >
                必須</span>
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <v-textarea
              v-model="$v.Form.faq_content.$model"
              placeholder="お問い合わせ内容"
              :error-messages="contentErrors"
              :counter="255"
              hide-details="auto"
              outlined
              dense
              color="#ff6e40"
            ></v-textarea>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="0" sm="4" md="3"></v-col>
          <v-col cols="12" sm="8">
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="6" sm="3" class="d-flex justify-end">
                <v-btn outlined width="100%" @click="closeDialogInquiry()">
                  <span>取消</span>
                </v-btn>
              </v-col>
              <v-col cols="6" sm="3" class="d-flex justify-end">
                <v-btn color="#ff6e40" width="100%" depressed :loading="submitStatus" @click="submit(Form)">
                  <span style="color: #fff">完了</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
    </v-card>
    <v-dialog v-model="dialog2" width="600px" persistent>
      <v-card height="180px" class="d-flex align-center justify-center">
        <div>
          <div class="mt-8" style="text-align: center; font-weight: bold; font-size: 18px">
            <span> お問い合わせありがとうございました。 </span>
          </div>
          <div class="d-flex justify-center mt-8">
            <v-btn color="#ff6e40" min-width="50%" depressed @click="dialog2 = false">
              <span style="color: #fff">OK</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import { required, maxLength, sameAs, email } from 'vuelidate/lib/validators'
import { helpers } from '@vuelidate/validators'
/* eslint-disable */

const allowedKana = helpers.regex(/^[ァ-ヶー]*$/)
const validMobile = helpers.regex(/^0[0-9]{9,10}$/)
const validZip = helpers.regex(/^[0-9]{3}[-]{0,1}[0-9]{4}$/)
export default {
  props: ['dialogInquiry'],
  data: () => ({
    dialog: false,
    dialog2: false,
    submitStatus: false,
    menu: false,
    show: false,
    faq_email: '',
    confirm_mail: '',
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    Form: {
      faq_sei: '',
      faq_mei: '',
      faq_sei_kana: '',
      faq_mei_kana: '',

      faq_tel: '',
      faq_email: '',
      faq_content: '',
    },
  }),
  validations: {
    Form: {
      faq_sei: {
        required,
        maxLength: maxLength(10),
      },
      faq_mei: {
        required,
        maxLength: maxLength(10),
      },
      faq_sei_kana: {
        // required,
        maxLength: maxLength(10),
        allowedKana,
      },
      faq_mei_kana: {
        // required,
        maxLength: maxLength(10),
        allowedKana,
      },
      faq_tel: {
        maxLength: maxLength(13),
        validMobile,
      },

      faq_content: {
        required,
        maxLength: maxLength(255),
      },
    },

    faq_email: {
      required,
      email: email,
    },
    confirm_mail: {
      required,
      email: email,
      sameAs: sameAs('faq_email'),
    },
  },
  computed: {
    seiErrors() {
      const errors = []
      if (!this.$v.Form.faq_sei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_sei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_sei.required && errors.push('必須項目')

      return errors
    },
    meiErrors() {
      const errors = []
      if (!this.$v.Form.faq_mei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_mei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_mei.required && errors.push('必須項目')

      return errors
    },
    seiKanaErrors() {
      const errors = []
      if (!this.$v.Form.faq_sei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_sei_kana.maxLength && errors.push('最大10文字までです！')
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.Form.faq_sei_kana.required && errors.push('必須項目')
      !this.$v.Form.faq_sei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },
    meiKanaErrors() {
      const errors = []
      if (!this.$v.Form.faq_mei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_mei_kana.maxLength && errors.push('最大10文字までです！')
      // // eslint-disable-next-line no-unused-expressions
      // !this.$v.Form.faq_mei_kana.required && errors.push('必須項目')
      !this.$v.Form.faq_mei_kana.allowedKana && errors.push('全角カナを入力してください。')

      return errors
    },

    mobileErrors() {
      const errors = []
      if (!this.$v.Form.faq_tel.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_tel.validMobile && errors.push('0から始まる10桁または11桁の番号を入力して下さい。')

      return errors
    },
    faq_address1Errors() {
      const errors = []
      if (!this.$v.Form.faq_address1.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_address1.maxLength && errors.push('最大100文字までです。')
      // eslint-disable-next-line no-unused-expressions

      return errors
    },
    faq_address2Errors() {
      const errors = []
      if (!this.$v.Form.faq_address2.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_address2.maxLength && errors.push('最大100文字までです。')

      return errors
    },

    emailErrors() {
      const errors = []
      if (!this.$v.faq_email.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faq_email.email && errors.push('メールアドレスを入力してください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.faq_email.required && errors.push('必須項目')

      return errors
    },

    confirmMailErrors() {
      const errors = []
      if (!this.$v.confirm_mail.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_mail.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_mail.sameAs && errors.push('上記「メールアドレス」と一致していません。')

      return errors
    },

    contentErrors() {
      const errors = []
      if (!this.$v.Form.faq_content.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions

        // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.faq_content.required && errors.push('必須項目')
      
      !this.$v.Form.faq_content.maxLength && errors.push('最大255文字までです。')
      // eslint-disable-next-line no-unused-expressions

      return errors
    },
  },
  watch: {
    dialogInquiry(value) {
      if (value === true) {
        this.$v.$reset()
      }
      this.dialog = this.dialogInquiry
    },
  },
  created() {
    this.dialog = this.dialogInquiry
  },
  methods: {
    ...mapActions('inquiryStore', ['createInquiry']),

    closeDialogInquiry() {
      this.dialog = false
      this.$emit('closeDialogInquiry')
    },

    submit(Form) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitStatus = true
        Form.faq_email = this.faq_email
        this.createInquiry(Form)
          .then(() => {
            this.submitStatus = false
            this.dialog2 = true
            this.clearForm()
          })
          .catch(error => {
            this.submitStatus = false
            this.apierror.status = error.response.data.status
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
      }
    },

    clearForm() {
      this.Form = {
        faq_sei: '',
        faq_mei: '',
        faq_sei_kana: '',
        faq_mei_kana: '',
        faq_tel: '',
        faq_email: '',
        faq_content: '',
      }
      this.faq_email = ''
      this.confirm_mail = ''
      this.closeDialogInquiry()
    },
  },
}
</script>

<style scoped></style>
