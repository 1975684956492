// eslint-disable-next-line import/prefer-default-export
export const bimiHomeData = {
  image1: require('@/assets/images0116/pc/file---1x-png@1x.png'),
  x40: require('@/assets/images0116/pc/file--------40@1x.png'),
  inCaseOfEmergency: require('@/assets/images0116/pc/in-case-of--emergency@1x.png'),
  image2: require('@/assets/images0116/pc/file---------------@1x.png'),
  bimistock1: require('@/assets/images0116/pc/bimistock-------------------------------------------------------@1x.png'),
  x221122_Bimistock_Image_01: require('@/assets/images0116/pc/221122-bimistock-image-01@1x.png'),
  x3259: require('@/assets/images0116/pc/file-----3259@1x.png'),
  image3: require('@/assets/images0116/pc/file---------------------------@1x.png'),
  x3288: require('@/assets/images0116/pc/file-----3288@1x.png'),
  x3263: require('@/assets/images0116/pc/file-----3262@1x.png'),
  overlapGroup8: require('@/assets/images0116/pc/221122-bimistock-image-11@1x.png'),
  x819: require('@/assets/images0116/pc/file----818@1x.png'),
  x01: require('@/assets/images0116/pc/01-@1x.png'),
  merit1: require('@/assets/images0116/pc/merit-1@1x.png'),
  image4: require('@/assets/images0116/pc/file--------------------@1x.png'),
  image5: require('@/assets/images0116/pc/file-------------------------------------------------------------2@1x.png'),
  overlapGroup13: require('@/assets/images0116/pc/221122-bimistock-image-12-----@1x.png'),
  x818: require('@/assets/images0116/pc/file----818@1x.png'),
  x02: require('@/assets/images0116/pc/02-@1x.png'),
  merit2: require('@/assets/images0116/pc/merit-1@1x.png'),
  image6: require('@/assets/images0116/pc/file----------------------------1x-png-1@1x.png'),
  image7: require('@/assets/images0116/pc/file-------------------------------------------------------------3@1x.png'),
  adobestock_129693242: require('@/assets/images0116/pc/adobestock-129693242@1x.png'),
  image8: require('@/assets/images0116/pc/file--@1x.png'),
  image9: require('@/assets/images0116/pc/file---@1x.png'),
  rollingStock: require('@/assets/images0116/pc/rolling-stock@1x.png'),
  image10: require('@/assets/images0116/pc/file-----------1x-png@1x.png'),
  bimistock2: require('@/assets/images0116/pc/file------------------------------------------------------------@1x.png'),
  image11: require('@/assets/images0116/pc/file-------------------------------------------------------------1@1x.png'),
  x3237: require('@/assets/images0116/pc/file-----3236@1x.png'),
  x3240: require('@/assets/images0116/pc/file-----3240@1x.png'),
  bimistock3: require('@/assets/images0116/pc/file-----------------------------------------------------bimistock--@1x.png'),
  x221122_Bimistock_Image_14: require('@/assets/images0116/pc/221122-bimistock-image-14@1x.png'),
  x820: require('@/assets/images0116/pc/file----818@1x.png'),
  merit3: require('@/assets/images0116/pc/merit-1@1x.png'),
  image12: require('@/assets/images0116/pc/file-----------------------@1x.png'),
  image13: require('@/assets/images0116/pc/file----------------------------------------------------------------@1x.png'),
  x03: require('@/assets/images0116/pc/03@1x.png'),
  overlapGroupContainer: require('@/assets/images0116/pc/221122-bimistock-image-03@1x.png'),
  image14: require('@/assets/images0116/pc/file-----------1x-png-1@1x.png'),
  image15: require('@/assets/images0116/pc/file-------------------------------------------------------------@1x.png'),
  overlapGroup17: require('@/assets/images0116/pc/file---4393@1x.png'),
  image16: require('@/assets/images0116/pc/file-----------@1x.png'),
  overlapGroup9: require('@/assets/images0116/pc/221122-bimistock-image-12l@1x.png'),
  x837: require('@/assets/images0116/pc/file----837@1x.png'),
  bimistock4: require('@/assets/images0116/pc/bimistock------------@1x.png'),
  x20231: require('@/assets/images0116/pc/2023-----1-----@1x.png'),
  x32891: require('@/assets/images0116/pc/file-----3289-1x-png@1x.png'),
  x32892: require('@/assets/images0116/pc/file-----3289-1x-png-1@1x.png'),
  x32893: require('@/assets/images0116/pc/file-----3289-1x-png-2@1x.png'),
  x32894: require('@/assets/images0116/pc/file-----3289-1x-png-3@1x.png'),
  deliciousPreservativeFoods1: require('@/assets/images0116/pc/delicious-preservative-foods@1x.png'),
  x32895: require('@/assets/images0116/pc/file-----3289-1x-png-4@1x.png'),
  x32896: require('@/assets/images0116/pc/file-----3289-1x-png-5@1x.png'),
  x32897: require('@/assets/images0116/pc/file-----3289-1x-png-6@1x.png'),
  x32898: require('@/assets/images0116/pc/file-----3289-1x-png-7@1x.png'),
  x32899: require('@/assets/images0116/pc/file-----3289-1x-png-8@1x.png'),
  x328910: require('@/assets/images0116/pc/file-----3289-1x-png-9@1x.png'),
  x328911: require('@/assets/images0116/pc/file-----3289-1x-png-10@1x.png'),
  x328912: require('@/assets/images0116/pc/file-----3289-1x-png-11@1x.png'),
  x328913: require('@/assets/images0116/pc/file-----3289@1x.png'),
  x221121_Bimistock__Pk1: require('@/assets/images0116/pc/221121-bimistock--------pk-1@1x.png'),
  x221121_Bimistock__Pk2: require('@/assets/images0116/pc/221121-bimistock-------pk-1@1x.png'),
  x221121_Bimistock__Pk3: require('@/assets/images0116/pc/221121-bimistock--------pk-3@1x.png'),
  x221121_Bimistock__Pk4: require('@/assets/images0116/pc/221121-bimistock----pk-1@1x.png'),
  x221122_Bimistock__Pk1: require('@/assets/images0116/pc/221122-bimistock---------pk@1x.png'),
  x221122_Bimistock__Pk2: require('@/assets/images0116/pc/221122-bimistock---------pk-1@1x.png'),
  x221121_Bimistock__Pk5: require('@/assets/images0116/pc/221121-bimistock------pk@1x.png'),
  x221122_Bimistock__Pk3: require('@/assets/images0116/pc/221122-bimistock-----pk@1x.png'),
  x221121_Bimistock__Pk6: require('@/assets/images0116/pc/221121-bimistock----------pk@1x.png'),
  x221121_Bimistock__Pk7: require('@/assets/images0116/pc/221121-bimistock----------pk-1@1x.png'),
  x221121_Bimistock__Pk8: require('@/assets/images0116/pc/221121-bimistock---pk-1@1x.png'),
  x221121_Bimistock__Pk9: require('@/assets/images0116/pc/221121-bimistock------pk-1@1x.png'),
  x221121_Bimistock__Pk10: require('@/assets/images0116/pc/221121-bimistock-------pk-2@1x.png'),
  image17: require('@/assets/images0116/pc/file----------@1x.png'),
  image18: require('@/assets/images0116/pc/file------------------@1x.png'),
  bimistock5: require('@/assets/images0116/pc/bimistock-----------------@1x.png'),
  image19: require('@/assets/images0116/pc/file---------------------------------------@1x.png'),
  overlapGroup15: require('@/assets/images0116/pc/file---4393@1x.png'),
  image20: require('@/assets/images0116/pc/file-----------@1x.png'),
  image21: require('@/assets/images0116/pc/file----------------------------1x-png@1x.png'),
  image22: require('@/assets/images0116/pc/file-------------1x-png-1@1x.png'),
  x221121_Bimistock_1: require('@/assets/images0116/pc/221121-bimistock------@1x.png'),
  x221121_Bimistock_2: require('@/assets/images0116/pc/221121-bimistock-------1@1x.png'),
  x221121_Bimistock_3: require('@/assets/images0116/pc/221121-bimistock-------2@1x.png'),
  x221121_Bimistock_4: require('@/assets/images0116/pc/221121-bimistock-------4@1x.png'),
  x221121_Bimistock_5: require('@/assets/images0116/pc/221121-bimistock-------3@1x.png'),
  x221121_Bimistock__Pk11: require('@/assets/images0116/pc/221121-bimistock--------pk@1x.png'),
  x221121_Bimistock__Pk12: require('@/assets/images0116/pc/221121-bimistock-------pk@1x.png'),
  x221121_Bimistock__Pk13: require('@/assets/images0116/pc/221121-bimistock--------pk-2@1x.png'),
  x221121_Bimistock__Pk14: require('@/assets/images0116/pc/221121-bimistock----pk@1x.png'),
  x221121_Bimistock__Pk15: require('@/assets/images0116/pc/221121-bimistock---pk@1x.png'),

  // copy form smartphone image
  image23: require('@/assets/images0116/sp/file------------1x-png@1x.png'),
  image24: require('@/assets/images0116/pc/file-----@1x.png'),
  image25: require('@/assets/images0116/pc/file-------------1x-png@1x.png'),
  x37: require('@/assets/images0116/pc/file-----------------@1x.png'),
  image26: require('@/assets/images0116/pc/file---------------------1x-png@1x.png'),
  image27: require('@/assets/images0116/pc/file----------------------------------------1x-png@1x.png'),
  sns: require('@/assets/images0116/pc/file--------sns--------------------@1x.png'),
  overlapGroup16: require('@/assets/images0116/pc/file---4393@1x.png'),
  image28: require('@/assets/images0116/pc/file-----------@1x.png'),
  deliciousPreservativeFoods2: require('@/assets/images0116/pc/delicious-preservative-foods-1@1x.png'),
  image29: require('@/assets/images0116/pc/file------------@1x.png'),
  bimistock6: require('@/assets/images0116/pc/bimistock----------------------------------------------------@1x.png'),
  x1: require('@/assets/images0116/pc/file---------1@1x.png'),
  image30: require('@/assets/images0116/pc/file------@1x.png'),
  bimistock7: require('@/assets/images0116/pc/file-bimistock------------------------------------------------------@1x.png'),
  imaizumi: require('@/assets/images0116/pc/imaizumi@1x.png'),
  adobestock_316641157: require('@/assets/images0116/pc/adobestock-316641157@1x.png'),
  bimistock8: require('@/assets/images0116/pc/bimistock-----------------------------------------------------1@1x.png'),
  image31: require('@/assets/images0116/pc/file-------------------1x-png@1x.png'),
  x3268: require('@/assets/images0116/pc/file-----3258@1x.png'),
  image32: require('@/assets/images0116/pc/file-------------------------@1x.png'),
  image33: require('@/assets/images0116/pc/file-------------@1x.png'),
  overlapGroup11: require('@/assets/images0116/pc/file---3077@1x.png'),
  x3260: require('@/assets/images0116/pc/file-----3260@1x.png'),
  x4: require('@/assets/images0116/pc/file--4@1x.png'),
  bimiStockAllRightsReserved: require('@/assets/images0116/pc/bimi-stock-all-rights-reserved@1x.png'),
  image34: require('@/assets/images0116/pc/file------1x-png@1x.png'),
}
