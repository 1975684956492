import agent from '../../api/agent'

const address = {
  namespaced: true,
  state: {
    addressList: [],
    address: undefined,
    selectedAddress: undefined,
  },
  mutations: {
    updateAddressList(state, value) {
      state.addressList = value
    },
    updateSelectedAddress(state, value) {
      state.selectedAddress = value
    },
  },
  actions: {
    async loadAddressList(context) {
      const result = await agent.Addresses.all()
      context.commit('updateAddressList', result.data)
    },

    async createAddress(context, value) {
      await agent.Addresses.add(value)
    },

    async loadAddress(context, id) {
      const result = await agent.Addresses.detail(id)
      context.commit('updateSelectedAddress', result.data)
    },

    async editAddress(context, value) {
      const obj = {
        ...value,
      }
      await agent.Addresses.update(obj)
    },
    async deleteAddress(context, id) {
      await agent.Addresses.delete(id)
      context.dispatch('loadAddressList')
    },
  },
}

export default address
