import agent from '../../api/agent'

const schedule = {
  namespaced: true,
  state: {
    scheduleList: [],
  },
  mutations: {
    updateScheduleList(state, newValue) {
      state.scheduleList = newValue
    },
  },
  actions: {
    async loadScheduleList(context) {
      const result = await agent.Schedule.all()
      context.commit('updateScheduleList', result.data)
    },
    async skipSchedule(context, value) {
      await agent.Schedule.skip(value)
    },
    async undoSkipSchedule(context, value) {
      await agent.Schedule.undoSkip(value)
    },
    async cancelSchedule(context, value) {
      await agent.Schedule.cancel(value)
    },
    async undoCancelSchedule(context, value) {
      await agent.Schedule.undoCancel(value)
    },
  },
}

export default schedule
