import agent from '../../api/agent'

const trailSet = {
  namespaced: true,
  state: {
    trailSetList: [],
  },
  mutations: {
    updateTrailSetList(state, newValue) {
      state.trailSetList = newValue
    },
  },
  actions: {
    async createTrailSet(context, value) {
      await agent.TrailSet.add(value)
    },
  },
}

export default trailSet
