import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ja from 'vuetify/lib/locale/ja'
import preset from './default-preset/preset'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    // iconfont: 'mdiSvg',
    iconfont: 'fa' || 'mdiSvg' || 'md',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
  lang: {
    locales: {
      ja,
    },
    current: 'ja',
  },
})
