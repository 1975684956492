import agent from '../../api/agent'

const counpons = {
  namespaced: true,
  state: {
    Info: [],
  },
  mutations: {
    updateInfo(state, newValue) {
      state.Info = newValue
    },
  },
  actions: {
    async getInfo(context, value) {
      const result = await agent.Coupons.getInfo(value)
      context.commit('updateInfo', result.data)
    },
  },
}

export default counpons
