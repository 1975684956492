import agent from '../../api/agent'

const subscriptionStore = {
  namespaced: true,
  state: {
    subscriptionList: [],
    selectedSubscription: undefined,
    subscriptionItems: [],
  },
  mutations: {
    updateSubscriptionList(state, newValue) {
      state.subscriptionList = newValue
    },
    updateSelectedSubscription(state, newValue) {
      state.selectedSubscription = newValue
    },
    updateSubscriptionItems(state, newValue) {
      state.subscriptionItems = newValue
    },
  },
  actions: {
    async loadSubscriptionList(context, query) {
      const result = await agent.Subscriptions.all(query)
      context.commit('updateSubscriptionList', result.data)
    },
    async loadSubscription(context, id) {
      const result = await agent.Subscriptions.detail(id)
      context.commit('updateSelectedSubscription', result.data)
    },
    async loadSubscriptionItems(context, query) {
      const result = await agent.Subscriptions.items(query)
      context.commit('updateSubscriptionItems', result.data)
    },
  },
}

export default subscriptionStore
