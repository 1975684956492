import agent from '../../api/agent'

const order = {
  namespaced: true,
  state: {
    orderList: [],
    selectedOrder: undefined,
  },
  mutations: {
    updateOrderList(state, newValue) {
      state.orderList = newValue
    },
    updateSelectedOrder(state, value) {
      state.selectedOrder = value
    },
  },
  actions: {
    async loadOrderList(context, value) {
      const result = await agent.Order.all(value)
      context.commit('updateOrderList', result.data)
    },

    async createOrder(context, value) {
      await agent.Order.add(value)
    },

    async loadOrder(context, value) {
      const result = await agent.Order.detail(value)
      context.commit('updateSelectedOrder', result.data)
    },

    async deleteOrder(context, value) {
      await agent.Order.cancel(value)
      context.dispatch('loadOrderList')
    },
  },
}

export default order
