const app = {
  namespaced: true,
  state: {
    OverlayStatus: false,
    absoluteOverlayStatus: false,
  },
  mutations: {
    setOverlayStatus(state, newValue) {
      state.OverlayStatus = newValue
    },
    setAbsoluteOverlayStatus(state, newValue) {
      state.absoluteOverlayStatus = newValue
    },
  },
}
export default app
/* eslint-disable */
  