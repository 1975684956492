import agent from '../../api/agent'

const card = {
  namespaced: true,
  state: {
    cardInfo: undefined,
    address: undefined,
    selectedAddress: undefined,
  },
  mutations: {
    updateCardInfo(state, value) {
      state.cardInfo = value
    },
    updateSelectedAddress(state, value) {
      state.selectedAddress = value
    },
  },
  actions: {
    async loadCardInfo(context) {
      const result = await agent.Card.detail()
      context.commit('updateCardInfo', result.data)
    },
    async deleteCard(context) {
      await agent.Card.delete()
      context.commit('updateCardInfo', undefined)
    },
    async createAddress(context, value) {
      await agent.Addresses.add(value)
    },

    async loadAddress(context, id) {
      const result = await agent.Addresses.detail(id)
      context.commit('updateSelectedAddress', result.data)
    },

    async editAddress(context, value) {
      const obj = {
        ...value,
      }
      await agent.Addresses.update(obj)
    },
    async deleteAddress(context, id) {
      await agent.Addresses.delete(id)
      context.dispatch('loadAddressList')
    },
  },
}

export default card
