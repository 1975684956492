<template>
  <v-sheet color="#ff6e40" class="py-12 px-8" style="position: relative; min-width: 410px">
    <div style="text-align: center; font-size: 42px" class="ml-3">
      <span class="f_text1">仕入れ先募集！</span>
    </div>
    <div class="mt-3 d-flex justify-center" style="font-size: 16px; line-height: 25px">
      <span class="f_text1" style="text-align: left; max-width: 300px">
        地域の食品メーカー、商工会議所、市町村等の皆様、ぜひ情報お待ちしてます。
      </span>
    </div>
    <div class="mt-6 px-6 d-flex justify-center">
      <v-btn depressed tile color="#fff" max-width="370px" min-height="55px" @click="dialogInquiry = true">
        <span class="f_text1 mx-3" style="font-size: 16px; color: #ff6e40">お問い合わせはコチラ</span>
      </v-btn>
    </div>
    <div id="backTop" class="d-flex justify-center mt-12">
      <v-img style="max-width: 300px" src="../views/images/home/n_3258_d.png" @click="clickMark('title1', 0)"></v-img>
    </div>
    <div class="d-flex justify-center mt-8 ml-8">
      <v-row style="max-width: 330px">
        <v-col class="f_text2 clicked">
          <span @click="information"> 料金・お支払い方法</span><br />
          <span @click="dialog = true">配送について</span><br />
          <span @click="faq">FAQ</span><br />
          <span @click="information">ご利用規約 </span><br />
          <span @click="company">運営会社 </span>
        </v-col>
        <v-col class="f_text2 clicked">
          <span @click="clickMark('title2', 1)"> BimiStockとは</span><br />
          <span @click="clickMark('title3', 4)">コンセプト</span><br />
          <span @click="subscription">定期便の商品</span><br />
          <span @click="set">お試しセット内容</span><br />
          <span style="color: #f89191">単品販売商品</span><br />
          <span @click="clickMark('title5', 2)">アレンジレシピ</span><br />
          <span @click="clickMark('imaizumi', 5)">今泉マユ子さん</span><br />
          <span @click="clickMark('title9', 3)">防災・減災情報 </span>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-center mt-12 mb-6">
      <a href="https://www.instagram.com/bimistock/" target="_blank">
        <v-img
          style="max-width: 58.675px; max-height: 58.675px"
          src="../views/images/home/n_19.png"
          class="mx-6 clicked"
        ></v-img>
      </a>
      <a href="https://www.facebook.com/people/Bimi-Stock/100089189154536/" target="_blank">
        <v-img
          class="mx-6 clicked"
          style="max-width: 33.073px; max-height: 63.748px"
          src="../views/images/home/n_23.png"
        ></v-img>
      </a>
    </div>
    <v-divider width="100%" color="#fff" class="mb-3"></v-divider>
    <div>
      <div class="d-flex justify-center" style="margin-left: -70px;">
        <span class="f_text3">BimiStock ALL RIGHTS RESERVED</span>
      </div>
      <div class="d-flex justify-center pointer" style="margin-left: -147px;">
        <span class="f_text3" @click="privacy">プライバシーポリシー</span>
      </div>
      <div id="information" class="d-flex justify-center" style="margin-left: -120px;">
        <span class="f_text3" @click="information">特定商取引法に基づく表示</span>
      </div>
      <div id="information" class="d-flex justify-center">
        <span class="f_text3" @click="information">令和２年度第３次補正 事業再築補助金により作成</span>
      </div>
    </div>
    <Tip :dialog="dialog" @closeDialog="closeDialog"></Tip>
    <Inquiry :dialog-inquiry="dialogInquiry" @closeDialogInquiry="closeDialogInquiry" />
  </v-sheet>
</template>
<script>
import Tip from '../views/ShippingFee.vue'
import Inquiry from '../views/Inquiry.vue'

export default {
  components: {
    Tip,
    Inquiry,
  },
  data: () => ({
    dialog: false,
    dialogInquiry: false,
  }),
  methods: {
    clickMark(val1, val2) {
      if (this.$route.name === 'home' || this.$route.name === 'homemobile') {
        this.scroll(val1)
      }
      if (this.$route.name !== 'home' && this.$route.name !== 'homemobile') {
        this.toHomeScroll(val2)
      }
    },
    scroll(key) {
      const element = document.getElementById(key)
      element.scrollIntoView()
    },
    toHomeScroll(val) {
      this.$router.push({
        path: '/',
        query: {
          Anchor: val,
        },
      })
    },
    closeDialog() {
      this.dialog = false
    },
    closeDialogInquiry() {
      this.dialogInquiry = false
    },
    subscription() {
      if (this.$route.name === 'subscriptionlist') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/subscriptionlist')
      }
    },
    set() {
      if (this.$route.name === 'setlist') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/setlist')
      }
    },
    faq() {
      if (this.$route.name === 'faq') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/faq')
      }
    },
    information() {
      if (this.$route.name === 'information') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/information')
      }
    },
    privacy() {
      if (this.$route.name === 'privacy') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/privacy')
      }
    },
    company() {
      if (this.$route.name === 'company') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/company')
      }
    },
    home() {
      this.$router.push('/')
    },
  },
}
</script>
<style scoped>
.f_text1 {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #fff;
}
.f_text2 {
  line-height: 37px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
}
.f_text3 {
  line-height: 27px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
}
#backTop :hover {
  cursor: pointer;
}
.clicked :hover {
  cursor: pointer;
}
#information :hover {
  cursor: pointer;
}
</style>
