<template>
  <v-app style="min-width: 370px">
    <Nav v-if="navController === 2" style="position: sticky; z-index: 100" />
    <NavbarMobile v-if="navController === 1" style="position: sticky; z-index: 100" />
    <slot />
    <Footer v-if="footerController === 2" style="position: sticky; z-index: 99" />
    <FooterMobile v-if="footerController === 1" style="position: sticky; z-index: 99" />
  </v-app>
</template>

<script>
import Nav from '@/components/Nav.vue'
import NavbarMobile from '@/components/NavbarMobile.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'

export default {
  components: {
    Nav,
    NavbarMobile,
    Footer,
    FooterMobile,
  },
  data: () => ({
    navController: 0,
    footerController: 0,
  }),
  mounted() {
    window.addEventListener('load', this.getWidth())
    window.addEventListener('resize', this.getWidth)
  },
  methods: {
    getWidth() {
      if (this.$route.name === 'home') {
        this.footerController = 2
        if (window.innerWidth <= 1200) {
          this.navController = 1
        } else {
          this.navController = 2
        }
      }
      if (this.$route.name !== 'home') {
        if (window.innerWidth <= 1200) {
          this.navController = 1
          this.footerController = 1
        } else {
          this.navController = 2
          this.footerController = 2
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
