import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'
import cartStore from './modules/cartStore'
import card from './modules/card'
import addressStore from './modules/addressStore'
import memberStore from './modules/memberStore'
import setStore from './modules/setStore'
import subscriptionStore from './modules/subscriptionStore'
import itemStore from './modules/itemStore'
import shippingFee from './modules/shippingFee'
import orderStore from './modules/orderStore'
import scheduleStore from './modules/scheduleStore'
import trailSetStore from './modules/trailSetStore'
import couponStore from './modules/couponStore'
import auth from './modules/auth'
import register from './modules/register'
import inquiryStore from './modules/inquiryStore'
import deliveryStore from './modules/deliveryStore'

import app from './modules/app'

Vue.use(Vuex)

const dataState = createPersistedState({
  storage: {
    getItem: key => Cookies.get(key),

    // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
    /* eslint-disable */
    setItem: (key, value) =>
      Cookies.set(key, value, {
        secure: true,
      }),
    /* eslint-disable */
    removeItem: key => Cookies.remove(key),
  },
  paths: ['auth'],
})

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    cartStore,
    couponStore,
    addressStore,
    memberStore,
    setStore,
    subscriptionStore,
    itemStore,
    shippingFee,
    orderStore,
    scheduleStore,
    trailSetStore,
    register,
    auth,
    app,
    card,
    inquiryStore,
    deliveryStore,
  },
  plugins: [dataState],
})
export default store
export const useStore = () => store
