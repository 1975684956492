import agent from '../../api/agent'

const set = {
  namespaced: true,
  state: {
    setList: [],
    selectedSet: undefined,
    setItems: [],
  },
  mutations: {
    updateSetList(state, newValue) {
      state.setList = newValue
    },
    updateSelectedSet(state, newValue) {
      state.selectedSet = newValue
    },
    updateSetItems(state, newValue) {
      state.setItems = newValue
    },
  },
  actions: {
    async loadSetList(context, query) {
      const result = await agent.Sets.all(query)
      context.commit('updateSetList', result.data)
    },
    async loadSetItems(context, query) {
      const result = await agent.Sets.items(query)
      context.commit('updateSetItems', result.data)
    },
    async createSet(context, items) {
      await agent.Sets.add(items)
    },
    async loadSet(context, id) {
      const result = await agent.Sets.detail(id)
      context.commit('updateSelectedSet', result.data)
    },
    async editSet(context, Sets) {
      const obj = {
        ...Sets,
      }
      await agent.Sets.update(obj)
    },
    async deleteSet(context, id) {
      await agent.Sets.delete(id)
    },
  },
}

export default set
