import agent from '../../api/agent'

const inquiry = {
  namespaced: true,

  actions: {
    async createInquiry(context, value) {
      await agent.Inquiry.add(value)
    },
  },
}

export default inquiry
