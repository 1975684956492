<template>
  <v-overlay
    :absolute="isAbsolute"
    :value="isShow"
    :z-index="1000"
  >
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  props: {
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),

}
</script>
