import agent from '../../api/agent'

const delivery = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async loadDelivery(context, id) {
      await agent.Delivery.detail(id)
    },
  },
}

export default delivery
