import agent from '../../api/agent'

const shippingFee = {
  namespaced: true,
  state: {
    ShippingFee: undefined,
  },
  mutations: {
    updateShippingFee(state, newValue) {
      state.ShippingFee = newValue
    },
    clearShippingFee(state) {
      state.ShippingFee = 0
    },
  },
  actions: {
    async loadShippingFee(context, value) {
      const result = await agent.Shippingfee.detail(value)
      context.commit('updateShippingFee', result.data)
    },
  },
}

export default shippingFee
