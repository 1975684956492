import agent from '../../api/agent'

const register = {
  namespaced: true,
  state: {
    memberInfo: '',
  },
  mutations: {
    updateEmailAddress(state, value) {
      state.memberInfo = value
    },
  },
  actions: {
    async getMemberInfo(context, id) {
      const result = await agent.Register.getMemberInfo(id)
      context.commit('updateEmailAddress', result.data)
    },
  },
}

export default register
