<template>
  <v-sheet min-height="800px" color="#ff6e40" style="position: relative" min-width="1200px">
    <div class="footer1" style="z-index: 97">
      <span class="f_text1" style="font-size: 50px; color: #fff; width: 361px; height: 68px; margin-left: -370px">仕入れ先募集！</span>
    </div>
    <div class="footer1 mt-3" style="z-index: 98">
      <span class="f_text1" style="font-size: 16px; color: #fff; width: 320px; height: 53px; line-height: 25px">地域の食品メーカー、商工会議所、市町村等の皆様、ぜひ情報お待ちしてます。</span>
    </div>
    <div class="footer1 mt-1" style="z-index: 99">
      <v-btn
        depressed
        tile
        color="#fff"
        width="254px"
        min-height="55px"
        style="margin-left: 400px"
        @click="dialogInquiry = true"
      >
        <span class="f_text1" style="font-size: 16px; color: #ff6e40">お問い合わせはコチラ</span>
      </v-btn>
    </div>
    <v-row style="padding-top: 230px; width: 1130px; margin: auto">
      <v-col>
        <div id="backTop" class="d-flex justify-center">
          <v-img
            style="max-width: 604.27px"
            src="../views/images/home/n_3258_d.png"
            @click="clickMark('title1', 0)"
          ></v-img>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-end" style="margin-top: -15px">
          <div style="min-width: 180px" class="f_text2 clicked">
            <span @click="clickMark('title2', 1)"> BimiStockとは</span><br />
            <span @click="clickMark('title3', 4)">コンセプト</span><br />
            <span @click="subscription">定期便の商品</span><br />
            <span @click="set">お試しセット内容</span><br />
            <span style="color: #f89191">単品販売商品</span><br />
            <span @click="clickMark('title5', 2)">アレンジレシピ</span><br />
            <span @click="clickMark('imaizumi', 5)">今泉マユ子さん</span><br />
            <span @click="clickMark('title9', 3)">防災・減災情報 </span>
          </div>
          <div style="min-width: 180px" class="f_text2 clicked">
            <span @click="information"> 料金・お支払い方法</span><br />
            <span @click="dialog = true">配送について</span><br />
            <span @click="faq">FAQ</span><br />
            <span @click="information">ご利用規約 </span><br />
            <span @click="company">運営会社 </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider
      style="position: absolute; bottom: 335px; left: 0; right: 0; margin: auto"
      width="1130px"
      color="#fff"
    ></v-divider>
    <div style="margin-top: 80px">
      <div class="d-flex justify-center" style="margin-left: -902px; margin-top: -50px">
        <span class="f_text3">BimiStock ALL RIGHTS RESERVED</span>
      </div>
      <div class="d-flex justify-center" style="margin-left: -814px">
        <div class="pointer">
          <span class="f_text3" @click="privacy">プライバシーポリシー</span>
        </div>
        <div id="information">
          <span class="f_text3 pl-8" @click="information">特定商取引法に基づく表示</span>
        </div>
      </div>
      <div class="d-flex justify-center" style="margin-left: -842px">
        <span class="f_text3">令和２年度第３次補正 事業再築補助金により作成</span>
      </div>
      <div class="d-flex justify-center" style="margin-left: 875px; margin-top: -60px">
        <v-img
          class="clicked mr-6"
          style="max-width: 33.073px; max-height: 63.748px"
          src="../views/images/home/n_23.png"
          onclick="window.open('https://www.facebook.com/people/Bimi-Stock/100089189154536/')"
        ></v-img>
        <v-img
          class="clicked ml-6"
          style="max-width: 58.675px; max-height: 58.675px"
          src="../views/images/home/n_19.png"
          onclick="window.open('https://www.instagram.com/bimistock/')"
        ></v-img>
      </div>
    </div>
    <Tip :dialog="dialog" @closeDialog="closeDialog"></Tip>
    <Inquiry :dialog-inquiry="dialogInquiry" @closeDialogInquiry="closeDialogInquiry" />
  </v-sheet>
</template>
<script>
import Tip from '../views/ShippingFee.vue'
import Inquiry from '../views/Inquiry.vue'

export default {
  components: {
    Tip,
    Inquiry,
  },
  data: () => ({
    dialog: false,
    dialogInquiry: false,
  }),
  methods: {
    clickMark(val1, val2) {
      if (this.$route.name === 'home') {
        this.scroll(val1)
      }
      if (this.$route.name !== 'home') {
        this.toHomeScroll(val2)
      }
    },
    scroll(key) {
      const element = document.getElementById(key)
      element.scrollIntoView()
    },
    toHomeScroll(val) {
      this.$router.push({
        path: '/',
        query: {
          Anchor: val,
        },
      })
    },
    closeDialog() {
      this.dialog = false
    },
    closeDialogInquiry() {
      this.dialogInquiry = false
    },
    subscription() {
      if (this.$route.name === 'subscriptionlist') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/subscriptionlist')
      }
    },
    set() {
      if (this.$route.name === 'setlist') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/setlist')
      }
    },
    faq() {
      if (this.$route.name === 'faq') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/faq')
      }
    },
    information() {
      if (this.$route.name === 'information') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/information')
      }
    },
    privacy() {
      if (this.$route.name === 'privacy') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/privacy')
      }
    },
    company() {
      if (this.$route.name === 'company') {
        window.scrollTo(0, 0)
      } else {
        this.$router.push('/company')
      }
    },
  },
}
</script>
<style scoped>
.footer1 {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  width: 354px;
  margin: auto;
}
.f_text1 {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
}
.f_text2 {
  line-height: 27px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #fff;
}
.f_text3 {
  line-height: 23px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #fff;
}
.f_text {
  line-height: 23px;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: #fff;
}
#backTop :hover {
  cursor: pointer;
}
.clicked :hover {
  cursor: pointer;
}
#information :hover {
  cursor: pointer;
}
</style>
