const cartStore = {
  namespaced: true,
  state: {
    count: 0,
    number: 1,
    cartList: [],
    selectedAddress: '',
  },
  mutations: {
    updateState(state, value) {
      Object.keys(value).forEach(v => {
        state[v] = value[v]
      })
    },
    selectAddress(state, value) {
      state.selectedAddress = value
    },
    addProduct(state, value) {
      const index = state.cartList.findIndex(obj => obj.product_code === value.product_code)
      if (index !== -1) {
        state.cartList[index].count += value.count
        if (state.cartList[index].count > 20) {
          state.cartList[index].count = 20
        }
      } else {
        const product = value
        if (product.count > 20) {
          product.count = 20
        }
        state.cartList.push(product)
      }
    },
    clearCart(state) {
      state.cartList = []
      state.count = 0
    },
    increNavCount(state) {
      state.count = 0

      // console.log('increNavCount', state.cartList)
      state.cartList.forEach(cl => {
        state.count += cl.count
      })

      // console.log('increNavCount', state.count)

      // state.count += value
      state.number = 1
    },
    increNum(state) {
      state.number += 1
    },
    decreNum(state) {
      if (state.number > 0) {
        state.number -= 1
      }
    },
    increCount(state) {
      state.count += 1
    },
    decreCount(state) {
      if (state.count > 0) {
        state.count -= 1
      }
    },
    clearNavCount(state, value) {
      state.count -= value
    },
  },
}

export default cartStore
