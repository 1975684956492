import agent from '../../api/agent'

const auth = {
  namespaced: true,
  state: {
    isLoggedIn: false,
    loaded: true,
    token: null,
    info: undefined,
    username: '',
  },
  getters: {
    getUserLoadStatus(state) {
      return state.loaded
    },
  },
  mutations: {
    updateInfo(state, newValue) {
      state.info = newValue
      state.username = newValue.mbr_sei + newValue.mbr_mei
    },
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload
    },
    updateToken(state, payload) {
      state.token = payload
    },
    clearInfo(state) {
      state.info = undefined
      state.username = ''
    },
  },
  actions: {
    clearLoginStatus(context) {
      context.commit('setIsLoggedIn', false)
      context.commit('updateToken', null)
    },
    async CancelMembership() {
      await agent.Auth.cancelMembership()
    },
    async ChangeAddress(context, value) {
      await agent.Auth.changeAddress(value)
    },
    async ChangeMobile(context, value) {
      await agent.Auth.changeMobile(value)
    },
    async ChangePassword(context, value) {
      await agent.Auth.changePassword(value)
    },
    async ChangeDescription(context, value) {
      await agent.Auth.changeDescription(value)
    },

    async ResetPassword(context, value) {
      await agent.Auth.resetPassword(value)
    },

    async ForgotPassword(context, value) {
      await agent.Auth.forgotPassword(value)
    },

    async loadInfo(context, value) {
      const result = await agent.Auth.info(value)
      context.commit('updateInfo', result.data.data)
    },
    setAuthStatus({ commit }, payload) {
      commit('setIsLoggedIn', payload)
    },
    updateToken({ commit }, payload) {
      commit('updateToken', payload)
    },
    async logout({ commit }) {
      await agent.Auth.logout().catch(error => {
        console.error('logout error', error.message)
      })
      commit('updateToken', null)
      commit('setIsLoggedIn', false)
    },
  },
}

export default auth
