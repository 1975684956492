<template>
  <div>
    <overlays :is-show="store.state.app.OverlayStatus" />
    <component :is="resolveLayout">
      <router-view />
    </component>
  </div>
</template>

<script setup>
import LayoutContent from '@/layouts/Content.vue'
import LayoutBlank from '@/layouts/Blank.vue'
import Overlays from '@/components/Overlays.vue'
import {
  useRoute,
} from 'vue-router/composables'
import {
  onMounted, computed,
} from 'vue'
// eslint-disable-next-line import/no-unresolved
import {
  useStore,
} from './store'

const route = useRoute()
const store = useStore()

store.dispatch('auth/loadInfo').catch(() => {
  console.log('user not logged in')
})
onMounted(() => {
  if (sessionStorage.getItem('cart')) {
    console.log('session:', sessionStorage)
    const obj = JSON.parse(sessionStorage.getItem('cart'))
    store.commit('cartStore/updateState', obj)
  }

  window.addEventListener('beforeunload', () => {
    console.log('beforeunload', store.state.cartStore)
    sessionStorage.setItem('cart', JSON.stringify(store.state.cartStore))
  })
})
const resolveLayout = computed(() => {
  if (route?.meta.layout === 'content') return LayoutContent

  return LayoutBlank
})

</script>
